import React, { Component } from 'react';
import styled from '@emotion/styled';
import { respondTo, css } from '@styles';
import ProductsFourInRow from '@components/products/ProductsFourInRow';
import { ProductPageIconsRow } from '@content/types/product';

import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import AnimationProductLine2 from '@animations/AnimationProductLine2';

const ProductsFunctionsRowWrapper = styled.div`
  position: relative;
  margin: 15px 0;
`;

const AnimationLineWrapper = styled.div`
  position: absolute;
  right: 50%;
  top: -42px;

  ${respondTo(
    1300,
    css`
      display: none;
    `
  )}
`;

interface ProductsFunctionsRowProps {
  settings: ProductPageIconsRow;
}

class ProductsFunctionsRow extends Component<ProductsFunctionsRowProps> {
  state = {
    sectionVisible: false,
  };

  render() {
    return (
      <AnimationTriggerWaypoint
        offset={250}
        onChange={() => this.setState({ sectionVisible: true })}
      >
        <ProductsFunctionsRowWrapper>
          <ProductsFourInRow data={this.props.settings.icons} />
          <AnimationLineWrapper>
            <AnimationProductLine2 sectionVisible={this.state.sectionVisible} />
          </AnimationLineWrapper>
        </ProductsFunctionsRowWrapper>
      </AnimationTriggerWaypoint>
    );
  }
}

export default ProductsFunctionsRow;
