import React from 'react';
import styled from '@emotion/styled';

import { colors, respondTo, breakpoints, css, mixins } from '@styles';
import BranchesListFull from '@components/branches/BranchesListFull';
import useBranchesQuery from '@graphql/queries/BranchesQuery';

const StyledBranchesListFull = styled(BranchesListFull)`
  padding-top: 90px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 1px;
    height: 50px;
    margin: auto;
    background-color: ${colors.green.dark};
  }

  ${respondTo(
    breakpoints.lg,
    css`
      padding-top: 75px;

      &::after {
        content: '';
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        margin: auto;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        ${mixins.gradientGreen};
      }
    `
  )}
`;

interface ProductBranchesProps {
  langcode: string;
  urls: Array<string>;
}

const ProductsBranches = (props: ProductBranchesProps) => {
  return (
    <StyledBranchesListFull {...useBranchesQuery()} langcode={props.langcode} urls={props.urls} />
  );
};

export default ProductsBranches;
