/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import Image, { GatsbyImageProps } from 'gatsby-image';
import Badge from '@components/common/Badge';
import * as utils from '@utils';

const MosaicWrapper = styled.div<{ thirdImage: boolean }>`
  display: flex;
  flex-direction: column;

  ${props =>
    respondFrom(
      breakpoints.lg,
      css`
        flex-direction: row;
        margin-top: 115px;
        min-height: ${props.thirdImage ? '830px' : '600px'};
      `
    )}
`;

const MosaicImages = styled.div<{ thirdImage: boolean }>`
  position: relative;
  width: 100%;
  height: ${p => (p.thirdImage ? 500 : 400)}px;

  ${respondFrom(
    breakpoints.lg,
    css`
      height: unset;
    `
  )}
`;

const MosaicTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  z-index: 2;

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 700px;
      width: 100%;
    `
  )}
`;

const MosaicTextBox = styled.div`
  background-color: ${colors.grayNurse};
  height: fit-content;
  overflow: hidden;

  h2 {
    margin-bottom: 20px;
  }

  p {
    &:first-of-type {
      margin-bottom: 30px;
    }
  }
`;

const Text2Wrapper = styled.div<{ thirdImage: boolean }>`
  padding: 30px;

  ${props =>
    respondFrom(
      breakpoints.lg,
      css`
        margin-top: auto;
        margin-left: ${props.thirdImage ? 200 : 40}px;
        margin-right: 40px;
        background-color: ${colors.white};
      `
    )}
`;
const MosaicFirsImage = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 142px);

  .gatsby-image-wrapper {
    width: 285px !important;

    img {
      width: 100% !important;
      height: auto !important;
      object-fit: contain !important;
    }
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      position: absolute;
      right: -20px;
      z-index: 2;
      left: unset;

      .gatsby-image-wrapper {
        width: 340px !important;

        img {
          object-fit: contain !important;
          width: 100% !important;
          height: auto !important;
        }
      }
    `
  )}
`;

const MosaicSecondImage = styled.div`
  position: absolute;
  top: 170px;
  left: calc(40% - 142px);

  .gatsby-image-wrapper {
    width: 285px !important;
    height: 200px;

    img {
      object-fit: contain !important;
      max-height: 240px;
    }
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      left: unset;

      .gatsby-image-wrapper {
        width: 500px !important;
        height: 400px;

        img {
          width: 100% !important;
          height: auto !important;
          max-height: 400px;
        }
      }
    `
  )}
`;

const MosaicThirdImage = styled.div`
  position: absolute;
  top: 375px;
  left: calc(60% - 142px);

  .gatsby-image-wrapper {
    width: 285px !important;
    height: 200px;

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      top: 455px;
      right: -60px;

      .gatsby-image-wrapper {
        width: 500px !important;
        height: 300px;

        img {
          width: 100% !important;
          height: auto !important;
          max-height: 300px;
        }
      }
    `
  )}
`;

interface MosaicProps {
  image1?: GatsbyImageProps;
  image2?: GatsbyImageProps;
  image3?: GatsbyImageProps;
  children: any;
  badge?: boolean;
  image1Alt: string;
  image2Alt: string;
  image3Alt: string;
  badgeText?: string;
  text2?: string;
}

const Mosaic = ({
  image1,
  image2,
  image3,
  children,
  badge,
  image1Alt,
  image2Alt,
  image3Alt,
  badgeText,
  text2,
}: MosaicProps) => (
  <MosaicWrapper thirdImage={typeof image3 !== 'undefined'}>
    <MosaicImages thirdImage={typeof image3 !== 'undefined'}>
      <MosaicFirsImage>{image1 && <Image {...image1} alt={image1Alt} />}</MosaicFirsImage>
      <MosaicSecondImage>{image2 && <Image {...image2} alt={image2Alt} />}</MosaicSecondImage>
      <MosaicThirdImage>{image3 && <Image {...image3} alt={image3Alt} />}</MosaicThirdImage>
    </MosaicImages>
    <MosaicTextWrapper>
      <MosaicTextBox>
        {badge && <Badge text={badgeText} hideOnMobile={true} />}
        {children}
      </MosaicTextBox>
      {text2 && (
        <Text2Wrapper thirdImage={typeof image3 !== 'undefined'}>
          {text2 && utils.SafeHtml(text2)}
        </Text2Wrapper>
      )}
    </MosaicTextWrapper>
  </MosaicWrapper>
);

export default Mosaic;
