import React from 'react';
import DiscountBoxes from '@components/common/DiscountBoxes';
import { ProductPageFunding } from '@content/types/product';

interface ProductsFundingsProps {
  settings: ProductPageFunding;
  langcode: string;
  urls: Array<string>;
}

const ProductsFundings = ({ settings, langcode, urls }: ProductsFundingsProps) => (
  <DiscountBoxes
    type="discounts"
    paragraph={settings.title}
    discounts={settings.items}
    blockMessage={settings.blockMessage}
    langcode={langcode}
    urls={urls}
  />
);

export default ProductsFundings;
