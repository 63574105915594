import get from 'lodash.get';
import { FileImageSharp } from '@content/types/api';
import {
  ProductPageBullets,
  ProductPageTariffs,
  ProductPageIcons,
  ProductPageComparisonItem,
  ProductPageTextLink,
  ProductPageNeedCash,
  ProductPageMosaic,
  ProductPageBannerSaveings,
  ProductPageTerminalModel,
} from '@content/types/product';

export const transformProductData = page => {
  const productPageLanguages = {};

  page.allSiteSettingEntityProduct.edges.forEach(edge => {
    const data = edge.node;

    const bullets: ProductPageBullets[] = [];
    const tariffs: ProductPageTariffs[] = [];
    const savings: ProductPageBannerSaveings[] = [];
    const textIcons: ProductPageIcons[] = [];
    const prodComparision: ProductPageComparisonItem[] = [];
    const titleButton: ProductPageTextLink[] = [];
    const imgDescrLink: ProductPageNeedCash[] = [];
    const mosaic: ProductPageMosaic[] = [];
    const terminalModels: ProductPageTerminalModel[] = [];

    data.relationships.field_components.forEach(component => {
      switch (component.__typename) {
        case 'paragraph__bullets_container':
          bullets.push(component);
          break;
        case 'paragraph__branches_tariff':
          tariffs.push(component);
          break;
        case 'paragraph__branches_saving_up_block':
          savings.push(component);
          break;
        case 'paragraph__ico_and_text':
          textIcons.push(component);
          break;
        case 'paragraph__porownanie_terminali':
          prodComparision.push(component);
          break;
        case 'paragraph__text_link':
          titleButton.push(component);
          break;
        case 'paragraph__zdjecie_opis_link':
          imgDescrLink.push(component);
          break;
        case 'paragraph__zdjecie_opis_link_ico':
          mosaic.push(component);
          break;
        case 'paragraph__terminal_models':
          terminalModels.push(component);
          break;
        default:
          break;
      }
    });
    const needCash = imgDescrLink[0];
    const fundingList = imgDescrLink;

    productPageLanguages[data.langcode] = {
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
        image: get(data, 'relationships.field_main_image.localFile.publicURL'),
      },
      hero: {
        title: get(data, 'field_hero_headline.value', ''),
        paragraph: get(data, 'field_hero_text.value', ''),
        button: get(data, 'field_hero_button_text', ''),
        imageAlt: get(data, 'field_hero_image.alt', ''),
        image: get(data, 'relationships.field_hero_image.localFile', null),
        imageMobile: get(data, 'relationships.field_hero_image_mobile.localFile', null),
        video: get(data, 'field_hero_video', ''),
        videoThumbnail: get(data, 'relationships.field_hero_video_thumbnail.localFile', null),
        videoThumbnailAlt: get(data, 'field_hero_video_thumbnail.alt', ''),
      },
      mosaic: {
        title: get(titleButton[0], 'field_paragraph_headline.value', ''),
        image1Alt: get(data, 'field_image_1.alt', ''),
        image2Alt: get(data, 'field_image_2.alt', ''),
        image3Alt: get(data, 'field_image_3.alt', ''),
        image1: get(data, 'relationships.field_image_1.localFile', null),
        image2: get(data, 'relationships.field_image_2.localFile', null),
        image3: get(data, 'relationships.field_image_3.localFile', null),
        online: get(data, ' field_kasa_online', false),
        onlineText: get(data, 'field_settings_text_6', ''),
        mainText: get(data, 'field_text_1.value', ''),
        secondaryText: get(data, 'field_text_2.value', ''),
      },
      iconsRow1: {
        icons: [
          {
            text: get(textIcons[0], 'field_text.value', ''),
            image: get(textIcons[0], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[0], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[1], 'field_text.value', ''),
            image: get(textIcons[1], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[1], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[2], 'field_text.value', ''),
            image: get(textIcons[2], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[2], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[3], 'field_text.value', ''),
            image: get(textIcons[3], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[3], 'field_ico.alt', ''),
          },
        ],
      },
      iconsRow2: {
        icons: [
          {
            text: get(textIcons[4], 'field_text.value', ''),
            image: get(textIcons[4], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[4], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[5], 'field_text.value', ''),
            image: get(textIcons[5], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[5], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[6], 'field_text.value', ''),
            image: get(textIcons[6], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[6], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[7], 'field_text.value', ''),
            image: get(textIcons[7], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[7], 'field_ico.alt', ''),
          },
        ],
      },
      bullets: bullets[0].relationships.field_bullets_item.map(bullet => {
        return {
          paragraph: get(bullet, 'field_text.value', ''),
          imageAlt: get(bullet, 'field_image.alt', ''),
          image: get(bullet, 'relationships.field_image.localFile', null) as FileImageSharp,
          buttonHref: get(bullet, 'field_btn_url', ''),
          buttonLabel: get(bullet, 'field_nc_button_text', ''),
          bonusImages: bullet.relationships.field_bullets_icons.map(icon =>
            get(icon, 'localFile.publicURL', '')
          ),
          video: get(bullet, 'field_video', 'fsdsdfdsf'),
        };
      }),
      terminalModels: terminalModels.map(model => {
        return {
          title: get(model, 'field_terminals_title.value', ''),
          description: get(model, 'field_terminals_description.value', ''),
          items: model.relationships.field_terminals_items.map(item => {
            return {
              bandTexts: get(item, 'field_terminal_band_text', []),
              buttonText: get(item, 'field_button_text', ''),
              description: get(item, 'field_terminal_description.value', ''),
              imageAlt: get(item, 'field_terminal_image.alt', ''),
              image: get(
                item,
                'relationships.field_terminal_image.localFile',
                null
              ) as FileImageSharp,
            };
          }),
        };
      }),
      mosaic1: {
        title: get(mosaic[0], 'field_paragraph_headline.value', ''),
        paragraph: get(mosaic[0], 'field_text.value', ''),
        button: get(mosaic[0], 'field_nc_button_text', ''),
        image: get(mosaic[0], 'relationships.field_image.localFile', null) as FileImageSharp,
        imageAlt: get(mosaic[0], 'field_image.alt', ''),
        imageDevice: get(
          mosaic[0],
          'relationships.field_image_1.localFile',
          null
        ) as FileImageSharp,
        imageDeviceAlt: get(mosaic[0], 'field_image_1.alt', ''),
        icons: [
          {
            text: get(textIcons[8], 'field_text.value', ''),
            image: get(
              textIcons[8],
              'relationships.field_ico.localFile.childImageSharp',
              null
            ) as FileImageSharp,
            imageAlt: get(textIcons[8], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[9], 'field_text.value', ''),
            image: get(
              textIcons[9],
              'relationships.field_ico.localFile.childImageSharp',
              null
            ) as FileImageSharp,
            imageAlt: get(textIcons[9], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[10], 'field_text.value', ''),
            image: get(
              textIcons[10],
              'relationships.field_ico.localFile.childImageSharp',
              null
            ) as FileImageSharp,
            imageAlt: get(textIcons[10], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[11], 'field_text.value', ''),
            image: get(
              textIcons[11],
              'relationships.field_ico.localFile.childImageSharp',
              null
            ) as FileImageSharp,
            imageAlt: get(textIcons[11], 'field_ico.alt', ''),
          },
        ],
      },
      mosaic2: {
        title: get(mosaic[1], 'field_paragraph_headline.value', ''),
        paragraph: get(mosaic[1], 'field_text.value', ''),
        button: get(mosaic[1], 'field_nc_button_text', ''),
        image: get(mosaic[1], 'relationships.field_image.localFile', null) as FileImageSharp,
        imageAlt: get(mosaic[1], 'field_image.alt', ''),
        imageDevice: get(
          mosaic[1],
          'relationships.field_image_1.localFile',
          null
        ) as FileImageSharp,
        imageDeviceAlt: get(mosaic[1], 'field_image_1.alt', ''),
        icons: [
          {
            text: get(textIcons[12], 'field_text.value', ''),
            image: get(textIcons[12], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[12], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[13], 'field_text.value', ''),
            image: get(textIcons[13], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[13], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[14], 'field_text.value', ''),
            image: get(textIcons[14], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[14], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[15], 'field_text.value', ''),
            image: get(textIcons[15], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[15], 'field_ico.alt', ''),
          },
        ],
      },
      productComparision: {
        title: get(prodComparision[0], 'field_paragraph_headline.value', ''),
        subtitle: get(prodComparision[0], 'field_description.value', ''),
        left: {
          text1: get(prodComparision[0], 'field_text_1.value', ''),
          text2: get(prodComparision[0], 'field_text_2.value', ''),
          text3: get(prodComparision[0], 'field_text_3', ''),
          text4: get(prodComparision[0], 'field_text_4.value', ''),
          button: get(prodComparision[0], 'field_kup_teraz', ''),
          link: get(prodComparision[0], 'field_zobacz_szczegoly', ''),
          label1: get(prodComparision[0], 'field_label_1', ''),
          label2: get(prodComparision[0], 'field_label_2', ''),
          label3: get(prodComparision[0], 'field_label_3', ''),
          label4: get(prodComparision[0], 'field_label_4', ''),
          image1: get(
            prodComparision[0],
            'relationships.field_image.localFile',
            null
          ) as FileImageSharp,
          image1Alt: get(prodComparision[0], 'field_image.alt', ''),
          image2: get(
            prodComparision[0],
            'relationships.field_image_1.localFile',
            null
          ) as FileImageSharp,
          image2Alt: get(prodComparision[0], 'field_image_1.alt', ''),
        },
        right: {
          text1: get(prodComparision[1], 'field_text_1.value', ''),
          text2: get(prodComparision[1], 'field_text_2.value', ''),
          text3: get(prodComparision[1], 'field_text_3', ''),
          text4: get(prodComparision[1], 'field_text_4.value', ''),
          button: get(prodComparision[1], 'field_kup_teraz', ''),
          link: get(prodComparision[1], 'field_zobacz_szczegoly', ''),
          label1: get(prodComparision[1], 'field_label_1', ''),
          label2: get(prodComparision[1], 'field_label_2', ''),
          label3: get(prodComparision[1], 'field_label_3', ''),
          label4: get(prodComparision[1], 'field_label_4', ''),
          image1: get(
            prodComparision[1],
            'relationships.field_image.localFile',
            null
          ) as FileImageSharp,
          image1Alt: get(prodComparision[1], 'field_image.alt', ''),
          image2: get(
            prodComparision[1],
            'relationships.field_image_1.localFile',
            null
          ) as FileImageSharp,
          image2Alt: get(prodComparision[1], 'field_image_1.alt', ''),
        },
      },
      tariffs: {
        title: get(titleButton[1], 'field_paragraph_headline.value', ''),
        button1: get(titleButton[1], 'field_nc_button_text', ''),
        button2: get(data, 'field_porownaj_taryfy_w_cenniku', ''),
        list: tariffs.map((tariff) => {
          return {
            name: get(tariff, 'field_branches_name', ''),
            price: parseFloat(get(tariff, 'relationships.field_date_from_api.iposfee')) +
              parseFloat(get(tariff, 'relationships.field_date_from_api.field_pl_locationfee')),
            profits: tariff.relationships.field_branches_profits.map(profit => ({
              name: get(profit, 'field_profits_name.value', ''),
              active: get(profit, 'field_profits_active', false),
            })),
          };
        }),
        pricePeriod: get(data, `field_settings_text_2`, ''),
      },
      needCash: {
        title: get(needCash, 'field_paragraph_headline.value', ''),
        paragraph: get(needCash, 'field_text.value', ''),
        imageAlt: get(needCash, 'field_image.alt', ''),
        image: get(needCash, 'relationships.field_image.localFile', null) as FileImageSharp,
      },
      iconsRow3: {
        title: get(titleButton[2], 'field_paragraph_headline.value', ''),
        icons: [
          {
            text: get(textIcons[16], 'field_text.value', ''),
            image: get(textIcons[16], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[16], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[17], 'field_text.value', ''),
            image: get(textIcons[17], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[17], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[18], 'field_text.value', ''),
            image: get(textIcons[18], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[18], 'field_ico.alt', ''),
          },
          {
            text: get(textIcons[19], 'field_text.value', ''),
            image: get(textIcons[19], 'relationships.field_ico.localFile.publicURL', ''),
            imageAlt: get(textIcons[19], 'field_ico.alt', ''),
          },
        ],
      },
      bannerSaveings: {
        title: get(savings[0], 'field_paragraph_headline.value', ''),
        paragraph: get(savings[0], 'field_branches_text.value', ''),
        button: get(savings[0], 'field_branches_button_text', ''),
      },
      fundings: {
        title: get(titleButton[3], 'field_paragraph_headline.value', ''),
        blockMessage: get(data, 'field_settings_text_7', ''),
        items: fundingList.map((item, index) => ({
          id: index,
          image: get(item, 'relationships.field_image.localFile', null) as FileImageSharp,
          imageAlt: get(item, 'field_image.alt', ''),
          paragraph: get(item, 'field_text.value', ''),
          buttonLabel: get(item, 'field_nc_button_text', ''),
          isHidden: get(item, 'field_hidden_or_disable', false),
        })),
      },
    };
  });

  return productPageLanguages;
};
