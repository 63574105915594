import React from 'react';
import { ProductPageTerminalModel } from '@content/types/product';
import styled from '@emotion/styled';
import { breakpoints, colors, css, respondFrom } from '@styles';
import * as utils from '@utils';
import Image from 'gatsby-image';
import ButtonLink from '@components/common/ButtonLink';

const TerminalModelsWrapper = styled.div``;

const Title = styled.div`
  padding: 0 30px;

  ${respondFrom(
    breakpoints.lg,
    css`
      text-align: center;
      max-width: 960px;
      margin: auto;
    `
  )}
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
  justify-content: center;
`;

const ItemWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;

  ${respondFrom(
    breakpoints.md,
    css`
      width: unset;
    `
  )}
`;

const Item = styled.div`
  position: relative;
  border: 1px solid ${colors.gray};
  background-color: ${colors.white};
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 350px;
`;

const ItemBandsWrapper = styled.div`
  position: absolute;
  top: 26px;
  width: 200px;
  right: -65px;
  transform: rotate(45deg);
  height: 20px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 2;
`;

const ItemBand = styled.div`
  text-transform: uppercase;
  color: ${colors.white};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  background-color: ${colors.blue.default};
  line-height: 20px;
  margin-top: -1px;
  white-space: nowrap;
  width: 100%;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;

  .gatsby-image-wrapper {
    width: 350px;
    height: 240px !important;
    margin-top: auto;

    img {
      object-fit: contain !important;
      top: unset !important;
      bottom: 0 !important;
      height: unset !important;
      max-height: 240px;
    }
  }
`;

const ItemDescription = styled.div`
  padding: 15px 5px;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButtonLink = styled(ButtonLink)`
  align-self: center;
  margin-bottom: 20px;
`;

interface ProductsComparisonProps {
  terminalModel: ProductPageTerminalModel;
}

const ProductsTerminalModels = ({ terminalModel }: ProductsComparisonProps) => {
  return (
    <TerminalModelsWrapper>
      <Title>
        {utils.SafeHtml(terminalModel.title)}
        {utils.SafeHtml(terminalModel.description)}
      </Title>
      <ItemsWrapper>
        {terminalModel.items.map((item, itemIndex) => {
          return (
            <ItemWrapper key={itemIndex}>
              <Item>
                <ItemBandsWrapper>
                  {item.bandTexts.map((bandText, bandIndex) => {
                    return <ItemBand key={bandIndex}>{bandText}</ItemBand>;
                  })}
                </ItemBandsWrapper>
                <ImageWrapper>
                  {item.image && <Image {...item.image.childImageSharp} alt={item.imageAlt} />}
                </ImageWrapper>
                <ItemDescription>{utils.SafeHtml(item.description)}</ItemDescription>
                {item.buttonText ? (
                  <ButtonWrapper>
                    <StyledButtonLink icon="arrow-right" to={'/sklep'}>
                      {item.buttonText}
                    </StyledButtonLink>
                  </ButtonWrapper>
                ) : (
                  ''
                )}
              </Item>
            </ItemWrapper>
          );
        })}
      </ItemsWrapper>
    </TerminalModelsWrapper>
  );
};

export default ProductsTerminalModels;
