import React from 'react';

import Page from '@components/layout/Page';
import ProductsPage from '@components/products';
import MetaPage from '@components/common/MetaPage';
import { LocationDependent } from '@content/types/general';
import DataLayer from '@components/layout/DataLayer';
import useProductPageQuery from '@graphql/queries/ProductQuery';
import { PageContext } from '@content/types/pageContext';

interface Props extends LocationDependent {
  pageContext: PageContext;
}

const ProductPage = (props: Props) => {
  const productPageQuery = useProductPageQuery()[props.pageContext.langcode];

  return (
    <Page>
      <MetaPage
        title={productPageQuery.seo.title}
        description={productPageQuery.seo.description}
        image={productPageQuery.seo.image}
      />
      <DataLayer location={props.location} />
      <ProductsPage
        {...productPageQuery}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </Page>
  );
};

export default ProductPage;
