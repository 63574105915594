import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, respondTo, breakpoints, css } from '@styles';
import Image, { GatsbyImageProps } from 'gatsby-image';
import Container from '@components/layout/Container';
import { Stylable, ImageSharp } from '@content/types/general';
import * as utils from '@utils';

const Wrapper = styled.section``;

const FourInRowList = styled.ul`
  position: relative;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &.no-lines {
    justify-content: space-between; /* should be for all types, but theres a problem with lines positioning */
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-direction: row;
      align-items: unset;
    `
  )}

  li {
    position: relative;
    padding: 30px;
    text-align: center;
    flex: 1;

    ${respondTo(
      breakpoints.lg,
      css`
        margin-bottom: 10px;
      `
    )}

    ${respondFrom(
      breakpoints.xl,
      css`
        max-width: 290px;
      `
    )}

    p, .p {
      line-height: 1.5;
      margin: 0;
      max-width: 290px;
      margin: auto;

      &:first-of-type {
        margin-bottom: 10px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 80px;
      right: -25px;
      margin: auto;
      height: 1px;
      width: 50px;
      background-color: ${colors.green.dark};
    }

    &.no-lines {
      padding: 0;

      p,
      .p {
        padding: 0 35px;
      }
      &.fullwidth {
        p,
        .p {
          padding: 0 5px;
        }
      }

      &::after {
        content: none;
      }
    }

    &.arrow-lines {
      /* arrow right */
      &::before {
        content: '';
        position: absolute;
        top: 80px;
        right: -25px;

        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 8px solid ${colors.green.dark};

        transform: translate(30%, -48%);
      }
    }

    &:last-of-type {
      &::after,
      &:before {
        content: none;
      }
    }

    /* vertical view */
    ${respondTo(
      breakpoints.lg,
      css`
        &::after {
          left: 50%;
          right: auto;
          bottom: -10px;
          top: auto;
          width: 30px;
          transform: translateX(-50%) rotate(90deg);
        }

        &.arrow-lines {
          &::before {
            left: 50%;
            right: auto;
            bottom: -50px;
            top: auto;
            transform: translateX(-50%) rotate(90deg);
          }
        }
      `
    )}
  }
`;

const FourInRowIcon = styled.div`
  position: relative;
  height: 100px;
  max-width: 180px;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const FourInRowImage = styled.div`
  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
    margin: auto;

    img {
      object-fit: contain !important;
    }
  }

  &.svg {
    margin-bottom: 10px;

    img {
      height: 90px;
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 5px;
`;

interface ProductFourInRowProps extends Stylable {
  data: Data[];
  storytellStartLine?: boolean;
  storytellEndLine?: boolean;
  noLines?: boolean;
  arrowLines?: boolean;
}

interface Data {
  title?: string;
  text?: string;
  image: string | GatsbyImageProps;
  imageAlt?: string;
}

const ProductFourInRow: React.FC<ProductFourInRowProps> = ({
  data,
  noLines,
  arrowLines,
  children,
  className,
}) => {
  return (
    <Wrapper className={className || ''}>
      <Container>
        <FourInRowList
          className={`
        ${noLines ? 'no-lines' : ''}`}
        >
          {data.map((element, index) => {
            if (!element) {
              return null;
            }

            const type: 'sharp' | 'svg' | 'raster' =
              typeof element.image !== 'string'
                ? 'sharp'
                : element.image.split('.').pop() !== 'svg' &&
                  element.image.indexOf('svg+xml') === -1
                ? 'raster'
                : 'svg';

            return (
              <li
                key={index}
                className={`
            ${noLines ? 'no-lines' : ''}
            ${arrowLines ? 'arrow-lines' : ''}
            ${type !== 'svg' ? 'fullwidth' : ''}
          `}
              >
                {element.image && (
                  <>
                    {type === 'sharp' ? (
                      // ImageSharp
                      <FourInRowImage>
                        <Image {...(element.image as GatsbyImageProps)} alt={element.imageAlt} />
                      </FourInRowImage>
                    ) : type === 'raster' ? (
                      // regular image (jpg, png)
                      <FourInRowImage>
                        <img src={element.image as string} alt={element.imageAlt} />
                      </FourInRowImage>
                    ) : (
                      // svg (including base64 svg)
                      <FourInRowIcon>
                        <img src={element.image as string} alt={element.imageAlt} />
                      </FourInRowIcon>
                    )}
                  </>
                )}

                {!!element.title && <Title>{utils.SafeHtml(element.title)}</Title>}

                {!!element.text && <div className={`p`}>{utils.SafeHtml(element.text)}</div>}
              </li>
            );
          })}
          {children}
        </FourInRowList>
      </Container>
    </Wrapper>
  );
};

export default ProductFourInRow;
