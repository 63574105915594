import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, respondTo } from '@styles';
import ProductsFourInRow from '@components/products/ProductsFourInRow';

import searchIcon from '@assets/svg/search.svg';
import paragonIcon from '@assets/svg/edytuj-image.svg';
import fewClientsIcon from '@assets/svg/few-clients.svg';
import zwrotIcon from '@assets/svg/zwrot.svg';

import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import AnimationProductLine1 from '@animations/AnimationProductLine1';
import { ProductPageIconsRow } from '@content/types/product';
import * as utils from '@utils';

const ProductsStorytellStartWrapper = styled.div`
  position: relative;
  padding-top: 30px;
  padding-bottom: 40px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-top: 100px;
      padding-bottom: 70px;
    `
  )}

  ${respondFrom(
    1300,
    css`
      padding-bottom: 150px;
    `
  )}

  h3 {
    position: relative;
    margin-bottom: 30px;

    ${respondFrom(
      breakpoints.lg,
      css`
        position: absolute;
        top: -140px;
        right: 0;
        width: 43%;
      `
    )}

    ${respondFrom(
      breakpoints.xl,
      css`
        top: -160px;
        width: 51%;
      `
    )}

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -35px;
      margin: auto;
      width: 30px;
      height: 1px;
      transform: rotate(90deg);
      background-color: ${colors.green.dark};

      ${respondFrom(
        breakpoints.lg,
        css`
          content: none;
        `
      )}
    }
  }
`;

const AnimationLine = styled(AnimationProductLine1)`
  position: absolute;
  bottom: 68px;
  left: calc(50% - 2px);

  ${respondTo(
    1300,
    css`
      display: none;
    `
  )}
`;

interface ProductStorytellStartProps {
  settings: ProductPageIconsRow;
}

class ProductsStorytellStart extends Component<ProductStorytellStartProps> {
  state = {
    sectionVisible: false,
  };

  render() {
    return (
      <AnimationTriggerWaypoint onChange={() => this.setState({ sectionVisible: true })}>
        <ProductsStorytellStartWrapper>
          <ProductsFourInRow data={this.props.settings.icons} />
          <AnimationLine sectionVisible={this.state.sectionVisible} />
        </ProductsStorytellStartWrapper>
      </AnimationTriggerWaypoint>
    );
  }
}

export default ProductsStorytellStart;
