import React from 'react';
import { useSpring, useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from './AnimationTriggerWaypoint';

interface Props extends Stylable, WaypointDependent {}

const strokeDasharrays = [370, 390];
const initialDelay = 800;
const stepsDelay = 500;

export default (props: Props) => {
  // lines animation
  const [animLines, animLinesSet] = useSprings(strokeDasharrays.length, i => ({
    from: { strokeDashoffset: (i === 0 ? -1 : 1) * strokeDasharrays[i] },
    config: config.default,
  }));
  animLinesSet(i => ({
    strokeDashoffset: props.sectionVisible ? 0 : (i === 0 ? -1 : 1) * strokeDasharrays[i],
    delay: initialDelay + 1500 + i * stepsDelay,
  }));

  // dots animation
  const [animDots, animDotsSet] = useSprings(2, i => ({
    from: { opacity: 0 /*, transform: 'scale(0)'*/ },
    config: config.default,
  }));
  animDotsSet(i => ({
    opacity: props.sectionVisible ? 1 : 0,
    // transform: props.sectionVisible ? 'scale(1)' : 'scale(0)',
    delay: initialDelay + 1500 + 500 + i * stepsDelay,
  }));

  // icon animation
  const [animIcon, animIconSet] = useSpring(i => ({
    from: { opacity: 0, transform: 'translate(0%, 2%)' },
    config: config.subtle,
  }));
  animIconSet({
    opacity: props.sectionVisible ? 1 : 0,
    transform: props.sectionVisible ? 'translate(0, 0)' : 'translate(0%, 2%)',
    delay: initialDelay,
  });

  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="494" height="195" viewBox="0 0 494 195">
        {/* mask - green line */}
        <mask id="mask-1">
          <g stroke="#FFFFFF" strokeDasharray="3" strokeWidth="2">
            <path
              d="M317.28096,159.102089 L317.28096,3.71904044 C317.28096,-6.77436981 325.787549,-15.2809596 336.28096,-15.2809596 C417.131849,-15.2809596 420.621241,-15.2809596 461.046686,-15.2809596"
              id="Path-Copy-4"
              transform="translate(389.163823, 71.910565) rotate(-270.000000) translate(-389.163823, -71.910565) "
            ></path>
            <path
              d="M27,148.383049 L27,-7 C27,-17.4934102 35.5065898,-26 46,-26 C126.85089,-26 108.902201,-26 149.327646,-26"
              id="Path-Copy-5"
              transform="translate(88.163823, 61.191524) scale(-1, 1) rotate(-270.000000) translate(-88.163823, -61.191524) "
            ></path>
          </g>
        </mask>

        {/* mask - grey line */}
        <mask id="mask-2">
          <g stroke="#FFFFFF" strokeDasharray="3" strokeWidth="2">
            <path d="M205.914502,32.3827634 L205.914502,5 C205.914502,2.23857625 203.675926,1.16732286e-13 200.914502,1.13686838e-13 L15,1.13686838e-13 C9.4771525,1.14701368e-13 5,4.4771525 5,10 L5,135.735134 L5,135.735134"></path>
            <path
              d="M440.914502,32.3827634 L440.914502,5 C440.914502,2.23857625 438.675926,-5.07265313e-16 435.914502,0 L250,0 C244.477153,1.01453063e-15 240,4.4771525 240,10 L240,156.110571 L240,156.110571"
              transform="translate(340.457251, 78.055285) scale(-1, 1) translate(-340.457251, -78.055285) "
            ></path>
          </g>
        </mask>

        <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Produkt" transform="translate(-939.000000, -143.000000)">
            <g id="1.-Top">
              <g id="product-header-image" transform="translate(0.000000, 100.000000)">
                <g id="Connect-lines" transform="translate(939.000000, 43.706572)">
                  {/* lines - grey */}
                  <g
                    id="grey"
                    transform="translate(0.293428, 14.000000)"
                    stroke="#929292"
                    mask="url(#mask-1)"
                  >
                    <animated.path
                      strokeDasharray={strokeDasharrays[0]}
                      strokeDashoffset={animLines[0].strokeDashoffset}
                      d="M317.28096,159.102089 L317.28096,3.71904044 C317.28096,-6.77436981 325.787549,-15.2809596 336.28096,-15.2809596 C417.131849,-15.2809596 420.621241,-15.2809596 461.046686,-15.2809596"
                      id="Path-Copy-4"
                      transform="translate(389.163823, 71.910565) rotate(-270.000000) translate(-389.163823, -71.910565) "
                    ></animated.path>
                    <animated.path
                      strokeDasharray={strokeDasharrays[0]}
                      strokeDashoffset={animLines[0].strokeDashoffset}
                      d="M27,148.383049 L27,-7 C27,-17.4934102 35.5065898,-26 46,-26 C126.85089,-26 108.902201,-26 149.327646,-26"
                      id="Path-Copy-5"
                      transform="translate(88.163823, 61.191524) scale(-1, 1) rotate(-270.000000) translate(-88.163823, -61.191524) "
                    ></animated.path>
                  </g>

                  {/* lines - green */}
                  <g
                    id="green"
                    transform="translate(14.000000, 0.293428)"
                    stroke="#009944"
                    mask="url(#mask-2)"
                  >
                    <animated.path
                      strokeDasharray={strokeDasharrays[1]}
                      strokeDashoffset={animLines[1].strokeDashoffset}
                      d="M205.914502,32.3827634 L205.914502,5 C205.914502,2.23857625 203.675926,1.16732286e-13 200.914502,1.13686838e-13 L15,1.13686838e-13 C9.4771525,1.14701368e-13 5,4.4771525 5,10 L5,135.735134 L5,135.735134"
                      id="Path-5"
                    ></animated.path>
                    <animated.path
                      strokeDasharray={strokeDasharrays[1]}
                      strokeDashoffset={animLines[1].strokeDashoffset}
                      d="M440.914502,32.3827634 L440.914502,5 C440.914502,2.23857625 438.675926,-5.07265313e-16 435.914502,0 L250,0 C244.477153,1.01453063e-15 240,4.4771525 240,10 L240,156.110571 L240,156.110571"
                      id="Path-5-Copy"
                      transform="translate(340.457251, 78.055285) scale(-1, 1) translate(-340.457251, -78.055285) "
                    ></animated.path>
                  </g>

                  {/* arrows */}
                  <animated.g transform="translate(14.000000, 0.293428)" style={animDots[0]}>
                    {/* arrows - grey */}
                    <polygon
                      id="Path-3-Copy-2"
                      fill="#929292"
                      transform="translate(171.311179, 13.715893) rotate(-90.000000) translate(-171.311179, -20.715893) "
                      points="166.826486 9.36986716 175.795871 9.36986716 171.264332 18.0619191"
                    ></polygon>
                    <polygon
                      id="Path-3-Copy-3"
                      fill="#929292"
                      transform="translate(291.311179, 13.715893) rotate(-270.000000) translate(-291.311179, -13.715893) "
                      points="286.826486 9.36986716 295.795871 9.36986716 291.264332 18.0619191"
                    ></polygon>
                  </animated.g>

                  <animated.g transform="translate(14.000000, 0.293428)" style={animDots[1]}>
                    {/* arrows - green */}
                    <polygon
                      id="Path-3"
                      fill="#009944"
                      points="0.826485757 132.369867 9.79587141 132.369867 5.26433248 141.061919"
                    ></polygon>
                    <polygon
                      id="Path-3-Copy"
                      fill="#009944"
                      points="436.826486 151.369867 445.795871 151.369867 441.264332 160.061919"
                    ></polygon>
                  </animated.g>

                  {/* icon 1 */}
                  <animated.g style={animIcon}>
                    <g
                      id="connect"
                      transform="translate(12.500000, 156.793428) rotate(-25.000000) translate(-12.500000, -156.793428) translate(4.000000, 148.293428)"
                      stroke="#009944"
                      strokeLinecap="round"
                    >
                      <path d="M0,11 C3.314,11 6,13.686 6,17" id="Stroke-1"></path>
                      <path d="M0,5 C6.62836364,5 12,10.3716364 12,17" id="Stroke-3"></path>
                      <path d="M0,0 C9.38825,0 17,7.61175 17,17" id="Stroke-5"></path>
                    </g>
                  </animated.g>

                  {/* icon 2 */}
                  <animated.g style={animIcon}>
                    <g
                      id="connect"
                      transform="translate(478.486778, 178.342360) scale(-1, 1) rotate(-25.000000) translate(-478.486778, -178.342360) translate(465.986778, 165.842360)"
                      stroke="#009944"
                      strokeLinecap="round"
                    >
                      <path
                        d="M0.204128585,15.611361 C4.62279525,15.611361 8.20412859,19.1926943 8.20412859,23.611361"
                        id="Stroke-1"
                      ></path>
                      <path
                        d="M0.294298493,7.18321379 C9.68448031,7.18321379 17.2942985,14.793032 17.2942985,24.1832138"
                        id="Stroke-3"
                      ></path>
                      <path
                        d="M0.0555423509,0.238756142 C13.3095424,0.238756142 24.0555424,10.9847561 24.0555424,24.2387561"
                        id="Stroke-5"
                      ></path>
                    </g>
                  </animated.g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
