import React from 'react';
import styled from '@emotion/styled-base';
import { respondFrom, breakpoints, css } from '@styles';
import Hero from '@components/common/Hero';
import { PageHero } from '@content/types/general';
import * as utils from '@utils';
import HeroVideoPlayer from '@components/common/HeroVideoPlayer';

const StyledHeroPlayer = styled(HeroVideoPlayer)`
  ${respondFrom(
    breakpoints.lg,
    css`
      bottom: -190px !important;
    `
  )}
`;

const StyledHero = styled(Hero)`
  ${props =>
    respondFrom(
      breakpoints.md,
      css`
        .arrow-down {
          position: ${props.hasVideo ? 'absolute' : 'relative'};
          bottom: ${props.hasVideo ? '-20px' : '0'};
          left: 0;
          right: 0;
        }
      `
    )}

  ${props =>
    respondFrom(
      breakpoints.lg,
      css`
        margin-bottom: ${props.hasVideo ? '250px !important' : '100px'};
        .arrow-down {
          position: ${props.hasVideo ? 'absolute' : 'relative'};
          bottom: ${props.hasVideo ? '-100px' : '0'};
        }
      `
    )}
`;

interface Props {
  settings: PageHero;
  langcode: string;
  urls: Array<string>;
  animation?: object;
}

export default (props: Props) => {
  const buttonText = props.settings.buttonText ? props.settings.buttonText : props.settings.button;
  const buttonRoute = props.settings.buttonRoute
    ? props.settings.buttonRoute
    : utils.langLink(props.langcode, props.urls[4]);

  return (
    <StyledHero
      hasVideo={!!props.settings.video}
      size="medium"
      image={props.settings.image ? props.settings.image.childImageSharp : undefined}
      imageMobile={
        props.settings.imageMobile ? props.settings.imageMobile.childImageSharp : undefined
      }
      imageAlt={props.settings.imageAlt}
      title={props.settings.title}
      paragraph={utils.SafeHtml(props.settings.paragraph)}
      buttonText={buttonText}
      buttonRoute={buttonRoute}
      buttonOutside={props.settings.buttonOutside}
      heroTheme="dark"
      additionalContent={
        <>
          {props.animation}
          {props.settings.video && (
            <StyledHeroPlayer
              videoThumbnail={props.settings.videoThumbnail}
              videoThumbnailAlt={props.settings.videoThumbnailAlt}
              videoUrl={props.settings.video}
            />
          )}
        </>
      }
    ></StyledHero>
  );
};
