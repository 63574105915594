import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import Container from '@components/layout/Container';
import Tariffs from '@components/common/Tariffs';
import { ProductPage } from '@content/types/product';
import ProductsTerminalModels from '@components/products/ProductsTerminalModels';

const ProductsComparisonWrapper = styled.div`
  background-color: ${colors.grayNurse};
  padding: 50px 0;
  padding-bottom: 80px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 100px;
      padding: 95px 0;
    `
  )}
`;

interface ProductsComparisonProps {
  settings: ProductPage;
  langcode: string;
  urls: Array<string>;
}

const ProductsComparison = ({ settings, langcode, urls }: ProductsComparisonProps) => {
  return (
    <ProductsComparisonWrapper>
      {settings.terminalModels.map((terminalModel, index) => (
        <ProductsTerminalModels key={index} terminalModel={terminalModel} />
      ))}
      <Container>
        <Tariffs
          subtitle={settings.tariffs.title}
          buttonLabel={settings.tariffs.button1}
          tariffs={settings.tariffs.list}
          compareButtonLabel={settings.tariffs.button2}
          langcode={langcode}
          urls={urls}
          pricePeriod={settings.tariffs.pricePeriod}
        />
      </Container>
    </ProductsComparisonWrapper>
  );
};

export default ProductsComparison;
