import React from 'react';
import styled from '@emotion/styled';
import { mixins } from '@styles';
import { useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

const LineWrapper = styled.div<WrapperProps>`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 7px;
    left: -2px;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    opacity: ${props => (props.sectionVisible ? 1 : 0)};
    ${mixins.gradientGreen};
    ${mixins.transitionDefault};
    transition-delay: 1300ms;
  }
`;

interface Props extends Stylable, WaypointDependent {}
interface WrapperProps {
  sectionVisible: boolean | undefined;
}

const strokeDasharrays = [1000, 100];
const initialDelay = 200;

export default (props: Props) => {
  // lines animation
  const [animLines, animLinesSet] = useSprings(2, i => ({
    from: { strokeDashoffset: (i === 0 ? 1 : -1) * strokeDasharrays[i] },
    config: config.molasses,
  }));
  animLinesSet(i => ({
    strokeDashoffset: props.sectionVisible ? 0 : (i === 0 ? 1 : -1) * strokeDasharrays[i],
    delay: initialDelay,
  }));

  return (
    <LineWrapper className={props.className} sectionVisible={props.sectionVisible}>
      <svg xmlns="http://www.w3.org/2000/svg" width="646" height="310" viewBox="0 0 646 310">
        <g fill="none" fillRule="evenodd">
          <animated.path
            strokeDasharray={strokeDasharrays[0]}
            strokeDashoffset={animLines[0].strokeDashoffset}
            stroke="#094"
            d="M591.5 1h27c12.703 0 23 10.297 23 23v230.143c0 12.703-10.297 23-23 23H24c-12.703 0-23 10.298-23 23v4.946"
          />
        </g>
      </svg>
    </LineWrapper>
  );
};
