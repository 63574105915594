import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import ButtonLink from '@components/common/ButtonLink';
import Image, { GatsbyImageProps } from 'gatsby-image';
import { Stylable } from '@content/types/general';
import * as utils from '@utils';

const DiscountBoxesItemWrapper = styled.div`
  padding: 30px;
  background-color: ${colors.grayNurse};
  margin-bottom: 30px;
  position: relative;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 50px;
      flex: 1;
      margin: 15px 0;
      margin-right: 15px;
      width: calc(100% / 3 - 30px);

      &:last-of-type {
        margin: 15px 0;
      }
    `
  )}

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 90px;
    margin: auto;
    margin-bottom: 30px;

    ${respondFrom(
      breakpoints.lg,
      css`
        height: 90px !important;
        margin: 0 auto 50px;
      `
    )}

    img {
      object-fit: contain !important;
    }
  }

  h3 {
    ${respondFrom(
      breakpoints.lg,
      css`
        min-height: 110px;
      `
    )}
  }
`;

interface DiscountBoxesItemProps extends Stylable {
  children?: React.ReactElement;
  image: GatsbyImageProps | null;
  title: string;
  linkTitle: string;
  linkHref: string;
  imageAlt?: string;
}

const DiscountBoxesItem = ({
  image,
  title,
  linkTitle,
  linkHref,
  className,
  children,
  imageAlt,
}: DiscountBoxesItemProps) => (
  <DiscountBoxesItemWrapper className={className}>
    <div>
      {image && <Image {...image} alt={imageAlt} />}
      <h3>{utils.SafeHtml(title)}</h3>
      <ButtonLink to={linkHref} color="text" icon="arrow-right">
        {linkTitle}
      </ButtonLink>
    </div>

    {children}
  </DiscountBoxesItemWrapper>
);

export default DiscountBoxesItem;
