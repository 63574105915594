import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import ProductsFourInRow from '@components/products/ProductsFourInRow';
import * as utils from '@utils';

import terminalIcon from '@assets/svg/terminal.svg';
import consultantIcon from '@assets/svg/consultant.svg';
import servicemanIcon from '@assets/svg/serviceman.svg';
import updateIcon from '@assets/svg/update.svg';
import { ProductPageIconsRow } from '@content/types/product';

const ProductsSupportWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 95px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 130px;
    `
  )}
`;

const ProductsSupportTitle = styled.div`
  text-align: left;
  position: relative;
  padding-bottom: 40px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 40px;
    background-color: #009944;
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      text-align: center;
      padding-bottom: 0;

      &::after {
        content: none;
      }
    `
  )}
`;

interface ProductsSupportProps {
  settings: ProductPageIconsRow;
}

const ProductsSupport = ({ settings }: ProductsSupportProps) => (
  <ProductsSupportWrapper>
    {settings.title && (
      <ProductsSupportTitle>{utils.SafeHtml(settings.title)}</ProductsSupportTitle>
    )}
    <ProductsFourInRow data={settings.icons} />
  </ProductsSupportWrapper>
);

export default ProductsSupport;
