import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '@styles';
import * as utils from '@utils';

const BadgeWrapper = styled.div`
  color: ${colors.white};
  background-color: ${colors.blue.default};
  text-align: center;
  padding: 5px 25px;
  transform: translate(-10px, 15px);
  position: relative;
  z-index: 5;
  font-weight: 100;
  text-transform: uppercase;
  display: inline-block;
  font-size: ${dimensions.fontSize.small}px;

  &::before {
    position: absolute;
    content: '';
    bottom: -10px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent ${colors.blue.dark} transparent transparent;
  }
`;

interface BadgeRecommendedProps {
  text?: string;
}

const BadgeRecommended = ({ text = 'Rekomendowany' }: BadgeRecommendedProps) => (
  <BadgeWrapper>
    <div>{utils.SafeHtml(text)}</div>
  </BadgeWrapper>
);

export default BadgeRecommended;
