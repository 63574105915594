import React from 'react';
import styled from '@emotion/styled';
import { useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

const LineWrapper = styled.div`
  position: relative;
`;

interface Props extends Stylable, WaypointDependent {}

const strokeDasharrays = [1000, 100];
const initialDelay = 100;

export default (props: Props) => {
  // lines animation
  const [animLines, animLinesSet] = useSprings(2, i => ({
    from: { strokeDashoffset: (i === 0 ? 1 : -1) * strokeDasharrays[i] },
    config: config.molasses,
  }));
  animLinesSet(i => ({
    strokeDashoffset: props.sectionVisible ? 0 : (i === 0 ? 1 : -1) * strokeDasharrays[i],
    delay: initialDelay,
  }));

  return (
    <LineWrapper className={props.className} sectionVisible={props.sectionVisible}>
      <svg xmlns="http://www.w3.org/2000/svg" width="642" height="123" viewBox="0 0 642 123">
        <animated.path
          strokeDasharray={strokeDasharrays[0]}
          strokeDashoffset={animLines[0].strokeDashoffset}
          stroke="#094"
          fill="none"
          fillRule="evenodd"
          d="M641.5.5c0 6.627-5.373 12-12 12H24c-12.703 0-23 10.297-23 23V100c0 12.703 10.297 23 23 23h27"
        />
      </svg>
    </LineWrapper>
  );
};
