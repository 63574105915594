import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import ProductsMosaic from '@components/products/ProductsMosaic';
import ProductsFourInRow from '@components/products/ProductsFourInRow';
import ButtonLink from '@components/common/ButtonLink';
import Title2 from '@components/common/Title2';
import { ProductPageMosaic } from '@content/types/product';
import * as utils from '@utils';

const ProductsFunctionLoginWrapper = styled.div`
  position: relative;
  margin-top: 70px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  &::before {
    width: 1px;
    height: 50px;
    top: -85px;
    background-color: ${colors.green.dark};
  }

  &::after {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    ${mixins.gradientGreen};
    top: -35px;
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 100px;

      &::before {
        top: -90px;
      }

      &::after {
        content: none;
      }
    `
  )}
`;

const ProductsMosaicContent = styled.div`
  padding: 90px 30px 45px 30px;
  background-color: ${colors.grayNurse};

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 90px 60px 85px 85px;
    `
  )}

  p {
    margin-right: 10px;
  }
`;

const Title = styled(Title2)`
  width: 100%;

  h2 {
    text-align: left;
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 620px;
      margin: auto;

      h2 {
        text-align: center;
      }
    `
  )}
`;

interface ProductsFunctionLogin {
  settings: ProductPageMosaic;
  langcode: string;
  urls: Array<string>;
}

const ProductsFunctionLogin = ({ settings, langcode, urls }: ProductsFunctionLogin) => {
  return (
    <ProductsFunctionLoginWrapper>
      <Title>{utils.SafeHtml(settings.title)}</Title>
      <ProductsMosaic
        imageMain={settings.image ? settings.image.childImageSharp : {}}
        imageMainAlt={settings.imageAlt}
        imageFloat={settings.imageDevice ? settings.imageDevice.childImageSharp : {}}
        imageFloatAlt={settings.imageDeviceAlt}
      >
        <ProductsMosaicContent>
          {utils.SafeHtml(settings.paragraph)}
          <ButtonLink to={utils.langLink(langcode, urls[12])} color="text" icon="arrow-right">
            {settings.button}
          </ButtonLink>
        </ProductsMosaicContent>
      </ProductsMosaic>
      <ProductsFourInRow data={settings.icons} noLines={true} />
    </ProductsFunctionLoginWrapper>
  );
};

export default ProductsFunctionLogin;
