import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import Mosaic from '@components/common/Mosaic';
import * as utils from '@utils';
import { MosaicProps } from '@content/types/vsTerminals';

const MosaicContentWrapper = styled.div`
  padding: 70px 20px 50px 30px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 75px 55px 75px 105px;
    `
  )}
`;

const ProductIntroTitle = styled.div`
  text-align: left;
  margin-bottom: 25px;

  ${respondFrom(
    breakpoints.lg,
    css`
      text-align: center;
      margin: 115px auto -60px;
      max-width: 500px;
    `
  )}
`;

interface ProductIntroProps {
  mosaic: MosaicProps;
}

const MosaicWrapper = ({ mosaic }: ProductIntroProps) => (
  <>
    {mosaic.title && <ProductIntroTitle>{utils.SafeHtml(mosaic.title)}</ProductIntroTitle>}

    <Mosaic
      image1={mosaic.image1 ? mosaic.image1.childImageSharp : undefined}
      image2={mosaic.image2 ? mosaic.image2.childImageSharp : undefined}
      image3={mosaic.image3 ? mosaic.image3.childImageSharp : undefined}
      image1Alt={mosaic.image1Alt}
      image2Alt={mosaic.image2Alt}
      image3Alt={mosaic.image3Alt}
      text2={mosaic.secondaryText}
    >
      <MosaicContentWrapper>{utils.SafeHtml(mosaic.mainText)}</MosaicContentWrapper>
    </Mosaic>
  </>
);

export default MosaicWrapper;
