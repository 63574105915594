import React from 'react';
import styled from '@emotion/styled';

import { fluidRange } from '@styles';

const StyledTitle2 = styled.div`
  h2 {
    max-width: 850px;
    margin: auto;
    text-align: center;
    ${fluidRange('marginTop', '50px', '80px')}
    ${fluidRange('marginBottom', '30px', '50px')}
  }
`;

interface Props {
  className?: string;
}

const Title2: React.FC<Props> = ({ children, className }) => (
  <StyledTitle2 className={className}>
    <h2>{children}</h2>
  </StyledTitle2>
);

export default Title2;
