import React from 'react';
import BranchBullets from '@components/branches/sections/BranchBullets';
import { ProductPageBullets } from '@content/types/product';

interface ProductFunctionsProps {
  storytellEnd?: boolean;
  settings: ProductPageBullets[];
  langcode: string;
}

const ProductFunctions = (props: ProductFunctionsProps) => (
  <BranchBullets
    noLastDot={true}
    reverse={true}
    circles={[
      { position: 0, rotate: -40, size: 'normal' },
      { position: 3, rotate: 55, size: 'normal' },
    ]}
    bullets={props.settings}
    langcode={props.langcode}
  />
);

export default ProductFunctions;
