/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { colors, respondFrom, respondTo, breakpoints, css } from '@styles';
import styled from '@emotion/styled';
import Image, { GatsbyImageProps } from 'gatsby-image';

const ProductMosaicWrapper = styled.div<ProductMosaicWrapperProps>`
  display: flex;
  flex-flow: column;
  position: relative;
  margin-bottom: 30px;

  ${props =>
    props.bottomLine
      ? respondTo(
          breakpoints.lg,
          css`
            padding-bottom: 50px;

            &::after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: -30px;
              margin: auto;
              width: 1px;
              height: 40px;
              background-color: ${colors.green.dark};
            }
          `
        )
      : null}

  ${respondFrom(
    breakpoints.lg,
    css`
      align-items: center;
      padding-bottom: 105px;
      margin-bottom: 0;
    `
  )}
`;

const ProductMosaicContent = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  order: 2;
  margin-left: -30px;
  width: calc(100% + 60px);

  &.side-right {
    left: auto;
    right: 0;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      margin: auto;
      width: 100%;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      width: calc(50% + 20px);
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 110px;
      left: 0;
      margin: auto;
      order: 1;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      width: calc(50% + 100px);
    `
  )}
`;

const ProductMosaicImageMain = styled.div`
  order: 1;
  margin-left: -35px;
  width: calc(100% + 70px);

  .gatsby-image-wrapper {
    width: 100%;
    padding-bottom: 64%;
    max-width: 100%;
    max-height: 100%;

    ${respondTo(
      breakpoints.lg,
      css`
        height: 0 !important;
      `
    )}

    img {
      object-fit: contain !important;
    }
  }

  ${respondFrom(
    breakpoints.md,
    css`
      margin: auto;
      width: auto;
      max-width: 100%;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-left: 50px;
      margin-left: auto;
      transform: translateX(150px);
      order: 2;
      margin-left: auto;
      margin-right: 0;

      &.side-right {
        margin-left: 0;
        margin-right: auto;
        padding-left: 0;
        padding-right: 50px;
        transform: translateX(-150px);
      }

      .gatsby-image-wrapper {
        padding-bottom: 0;
      }
    `
  )}
`;

const ProductMosaicImageFloat = styled.div`
  position: absolute;
  left: -25vw;
  top: calc(25vw);
  margin: auto;
  z-index: 2;
  pointer-events: none;

  &.side-right {
    left: auto;
    right: -40vw;
  }

  .gatsby-image-wrapper {
    margin: auto;
    max-width: 70vw;

    img {
      object-fit: contain !important;
    }
  }

  ${respondFrom(
    breakpoints.md,
    css`
      top: 36%;
      bottom: 0;
      left: -150px;

      &.side-right {
        left: auto;
        right: -150px;
      }

      .gatsby-image-wrapper {
        max-width: 400px;
      }
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;

      &.side-right {
        left: 0;
        right: 0;
      }

      .gatsby-image-wrapper {
        max-width: none;
      }
    `
  )}
`;

interface ProductMosaicProps {
  children: any;
  right?: boolean;
  imageMain: GatsbyImageProps;
  imageFloat?: GatsbyImageProps;
  bottomLine?: boolean;
  imageMainAlt?: string;
  imageFloatAlt?: string;
}

interface ProductMosaicWrapperProps {
  bottomLine?: boolean;
}

const ProductMosaic = ({
  children,
  right,
  imageMain,
  imageFloat,
  bottomLine,
  imageMainAlt,
  imageFloatAlt,
}: ProductMosaicProps) => (
  <ProductMosaicWrapper bottomLine={bottomLine}>
    <ProductMosaicContent className={right ? 'side-right' : ''}>{children}</ProductMosaicContent>
    <ProductMosaicImageMain className={right ? 'side-right' : ''}>
      <Image {...imageMain} alt={imageMainAlt} />
    </ProductMosaicImageMain>

    {imageFloat && (
      <ProductMosaicImageFloat className={right ? 'side-right' : ''}>
        <Image {...imageFloat} alt={imageFloatAlt} />
      </ProductMosaicImageFloat>
    )}
  </ProductMosaicWrapper>
);

export default ProductMosaic;
