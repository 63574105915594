import React from 'react';

import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import ProductsHero from '@components/common/PageHero';
import MosaicWrapper from '@components/common/MosaicWrapper';
import ProductsIntro from '@components/products/ProductsIntro';
import ProductsStorytellStart from '@components/products/ProductsStorytellStart';
import ProductFunctions from '@components/products/ProductsFunctions';
import ProductsFunctionsRow from '@components/products/ProductsFunctionsRow';
import ProductsFunctionLogin from '@components/products/ProductsFunctionLogin';
import ProductsFunctionManagement from '@components/products/ProductsFunctionManagement';
import ProductsComparison from '@components/products/ProductsComparison';
import ProductsSupport from '@components/products/ProductsSupport';
import BannerSavings from '@components/common/BannerSavings';
import ProductsFundings from '@components/products/ProductsFundings';
import ProductsBranches from '@components/products/ProductsBranches';
import { ProductPage } from '@content/types/product';
import styled from '@emotion/styled';
import AnimationProductHero from '@animations/AnimationProductHero';
import { breakpoints, respondFrom } from '@styles';
import { css } from '@emotion/core';

const StyledAnimationProductHero = styled(AnimationProductHero)`
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(29%, 22%);
    `
  )}
`;

export interface ProductProps extends ProductPage {
  langcode: string;
  urls: Array<string>;
}

export default (props: ProductProps) => {
  return (
    <Page>
      <ProductsHero
        animation={<StyledAnimationProductHero sectionVisible={true} />}
        settings={props.hero}
        langcode={props.langcode}
        urls={props.urls}
      />

      <Container>
        <MosaicWrapper mosaic={props.mosaic} />
        <ProductsStorytellStart settings={props.iconsRow1} />
        <ProductFunctions settings={props.bullets} storytellEnd={true} langcode={props.langcode} />
        <ProductsFunctionsRow settings={props.iconsRow2} />
        <ProductsFunctionLogin
          settings={props.mosaic1}
          langcode={props.langcode}
          urls={props.urls}
        />
        <ProductsFunctionManagement
          settings={props.mosaic2}
          langcode={props.langcode}
          urls={props.urls}
        />
      </Container>

      <ProductsComparison settings={props} langcode={props.langcode} urls={props.urls} />

      <Container>
        <ProductsSupport settings={props.iconsRow3} />
      </Container>

      <BannerSavings
        title={props.bannerSaveings.title}
        text={props.bannerSaveings.paragraph}
        buttonText={props.bannerSaveings.button}
        langcode={props.langcode}
        urls={props.urls}
      />

      <Container>
        <ProductsFundings settings={props.fundings} langcode={props.langcode} urls={props.urls} />
        <ProductsBranches langcode={props.langcode} urls={props.urls} />
      </Container>
    </Page>
  );
};
