import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import Container from '@components/layout/Container';
import ButtonLink from '@components/common/ButtonLink';
import * as utils from '@utils';

import pigIcon from '@assets/svg/skarbonka.svg';

const BannerSavingsWrapper = styled.div`
  background-color: ${colors.grayNurse};
  margin-bottom: 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 150px;
    `
  )}
`;

const BannerSavingsInner = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;

  position: relative;
  padding: 30px 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      align-items: center;
    `
  )}

  &.no-button {
    padding-bottom: 80px;

    ${respondFrom(
      breakpoints.lg,
      css`
        padding: 30px 0;
      `
    )}
  }

  > div {
    h3 {
      strong {
        display: block;
        font-size: 50px;
      }
    }

    p {
      strong {
        display: block;
      }
    }

    ${respondFrom(
      breakpoints.lg,
      css`
        &:nth-of-type(1) {
          min-width: 405px;
        }
        &:nth-of-type(2) {
          margin: 0 30px;
          max-width: 575px;
        }

        p,
        h3 {
          margin: 0;

          strong {
            display: inline;
          }
        }

        h3 strong {
          font-size: 30px;
        }
      `
    )}
  }
`;

const PigIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: -20px;
  width: 80px;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 65px;
      left: -40px;
      right: auto;
    `
  )}

  ${respondFrom(
    breakpoints.xxl,
    css`
      width: 80px;
      left: -7vw;
    `
  )}
`;

interface BannerSavingsProps {
  title?: string;
  text?: string;
  buttonDisabled?: boolean;
  buttonText?: string;
  langcode: string;
  urls: Array<string>;
}

const BannerSavings: React.FC<BannerSavingsProps> = ({
  title = '',
  text = '',
  buttonDisabled,
  buttonText = 'Kup teraz',
  langcode,
  urls,
}) => (
  <BannerSavingsWrapper>
    <Container>
      <BannerSavingsInner className={buttonDisabled ? 'no-button' : ''}>
        <PigIcon src={pigIcon} alt="" />
        <div>
          <h3>{utils.SafeHtml(title)}</h3>
        </div>
        <div>{utils.SafeHtml(text)}</div>
        {buttonDisabled ? null : (
          <div>
            <ButtonLink to={utils.langLink(langcode, urls[4])} icon="arrow-right">
              {buttonText}
            </ButtonLink>
          </div>
        )}
      </BannerSavingsInner>
    </Container>
  </BannerSavingsWrapper>
);

export default BannerSavings;
