import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import { BranchSectionDiscountsProps } from '@content/types/branch';
import DiscountBoxesItem from '@components/common/DiscountBoxesItem';
import BlockedBoxMessage from '@components/common/BlockedBoxMessage';
import * as utils from '@utils';

const DiscountBoxesWrapper = styled.div`
  h2,
  p {
    text-align: left;

    ${respondFrom(
      breakpoints.lg,
      css`
        text-align: center;
      `
    )}
  }
`;

const DiscountBoxesList = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 45px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin: 45px 0;
      flex-flow: row;
    `
  )}
`;

interface DiscountBoxesProps extends BranchSectionDiscountsProps {
  blockMessage?: string;
  langcode: string;
  urls: Array<string>;
}

const DiscountBoxes = (props: DiscountBoxesProps) => {
  return (
    <DiscountBoxesWrapper>
      {props.title && <h2>{utils.SafeHtml(props.title)}</h2>}
      {props.paragraph && utils.SafeHtml(`<p>${props.paragraph}</p>`)}

      <DiscountBoxesList>
        {props.discounts.map(discount =>
          discount.isHidden ? null : (
            <DiscountBoxesItem
              key={discount.id}
              image={discount.image ? discount.image.childImageSharp : null}
              imageAlt={discount.imageAlt}
              title={discount.paragraph}
              linkTitle={discount.buttonLabel}
              linkHref={utils.langLink(props.langcode, props.urls[17])}
              // className={index === 1 ? 'blocked' : ''}
            >
              {/* {props.blockMessage && props.blockMessage !== '' && (
              <BlockedBoxMessage blocked={index === 1}>{props.blockMessage}</BlockedBoxMessage>
            )} */}
            </DiscountBoxesItem>
          )
        )}
      </DiscountBoxesList>
    </DiscountBoxesWrapper>
  );
};

export default DiscountBoxes;
