import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import ProductsMosaic from '@components/products/ProductsMosaic';
import ProductsFourInRow from '@components/products/ProductsFourInRow';
import ButtonLink from '@components/common/ButtonLink';
import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import AnimationCircle from '@animations/AnimationCircle';
import { ProductPageMosaic } from '@content/types/product';
import * as utils from '@utils';

const ProductsFunctionLoginWrapper = styled.div`
  margin-top: 30px;
  position: relative;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 100px;
    `
  )}

  h2 {
    margin-bottom: 30px;

    ${respondFrom(
      breakpoints.lg,
      css`
        max-width: 620px;
        margin: auto;
        text-align: center;
        margin-bottom: 50px;
      `
    )}
  }
`;

const ProductsMosaicContent = styled.div`
  padding: 80px 30px 45px 30px;
  background-color: ${colors.grayNurse};

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 95px 75px 100px 100px;
    `
  )}
`;

const AnimationCircleWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -370px;
`;

interface ProductsFunctionLoginProps {
  settings: ProductPageMosaic;
  langcode: string;
  urls: Array<string>;
}

class ProductsFunctionLogin extends Component<ProductsFunctionLoginProps> {
  state = {
    isVisible: false,
  };

  render() {
    const { settings, urls } = this.props;

    return (
      <ProductsFunctionLoginWrapper>
        {utils.SafeHtml(settings.title)}
        <AnimationTriggerWaypoint onChange={() => this.setState({ isVisible: true })}>
          <ProductsMosaic
            right={true}
            imageMain={settings.image ? settings.image.childImageSharp : {}}
            imageMainAlt={settings.imageAlt}
            imageFloat={settings.imageDevice ? settings.imageDevice.childImageSharp : {}}
            imageFloatAlt={settings.imageDeviceAlt}
            bottomLine={true}
          >
            <ProductsMosaicContent>
              {utils.SafeHtml(settings.paragraph)}
              <ButtonLink to={utils.langLink(this.props.langcode, urls[4])} icon="arrow-right">
                {settings.button}
              </ButtonLink>
            </ProductsMosaicContent>
          </ProductsMosaic>

          <AnimationCircleWrapper>
            <AnimationCircle
              sectionVisible={this.state.isVisible}
              className="size-large"
              animateReverse={true}
            />
          </AnimationCircleWrapper>
        </AnimationTriggerWaypoint>
        <ProductsFourInRow data={settings.icons} />
      </ProductsFunctionLoginWrapper>
    );
  }
}

export default ProductsFunctionLogin;
